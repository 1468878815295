import { Component } from 'react';
import CookieConsent, { Cookies as CookieProvider } from 'react-cookie-consent';
import {
  CookieConsentWrapper,
  Options,
} from 'components/Cookies/Cookies.styles';
import Option from 'components/Option';

import { FormattedMessage } from 'react-intl';
import {
  cookiesExpireTime,
  functionalCookies,
  statisticCookies,
  marketingCookies,
} from 'config';

import messages from './Cookies.messages';

class Cookies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propertiesShow: false,
      statisticChecked: true,
      marketingChecked: true,
    };
  }

  propertiesToggle = () => {
    this.setState((prevState) => ({
      propertiesShow: !prevState.propertiesShow,
    }));
  };

  statisticCookiesToggle = () => {
    if (
      CookieProvider.get(statisticCookies) === 'true' ||
      !CookieProvider.get(statisticCookies)
    ) {
      CookieProvider.set(statisticCookies, false, {
        expires: cookiesExpireTime,
      });
    } else {
      CookieProvider.set(statisticCookies, true, {
        expires: cookiesExpireTime,
      });
    }
    this.setState((prevState) => ({
      statisticChecked: !prevState.statisticChecked,
    }));
  };

  marketingCookiesToggle = () => {
    if (
      CookieProvider.get(marketingCookies) === 'true' ||
      !CookieProvider.get(marketingCookies)
    ) {
      CookieProvider.set(marketingCookies, false, {
        expires: cookiesExpireTime,
      });
    } else {
      CookieProvider.set(marketingCookies, true, {
        expires: cookiesExpireTime,
      });
    }
    this.setState((prevState) => ({
      marketingChecked: !prevState.marketingChecked,
    }));
  };

  checkActual = () => {
    if (!CookieProvider.get(statisticCookies)) {
      CookieProvider.set(statisticCookies, true, {
        expires: cookiesExpireTime,
      });
    }
    if (!CookieProvider.get(marketingCookies)) {
      CookieProvider.set(marketingCookies, true, {
        expires: cookiesExpireTime,
      });
    }

    window.location.reload();
  };

  render() {
    return (
      <CookieConsentWrapper>
        <CookieConsent
          buttonText={<FormattedMessage {...messages.acceptBtn} />}
          cookieName={functionalCookies}
          expires={cookiesExpireTime}
          enableDeclineButton
          declineButtonText={<FormattedMessage {...messages.propertiesBtn} />}
          hideOnDecline={false}
          flipButtons
          buttonWrapperClasses='Wrapper'
          disableStyles
          buttonClasses='Accept'
          declineButtonClasses='Properties'
          onDecline={this.propertiesToggle}
          onAccept={this.checkActual}
          setDeclineCookie={false}
        >
          <h4>
            <FormattedMessage {...messages.privacyHeader} />
          </h4>
          <p>
            <FormattedMessage
              {...messages.privacyText}
              values={{
                privacyPolicy: (
                  <a href='https://start.com.pl/prawne'>
                    <FormattedMessage {...messages.privacyLink} />
                  </a>
                ),
                cookiesPolicy: (
                  <a href='https://api.start.com.pl/sites/default/files/regulations/PB%20START%20-%20RODO%20-%20polityka%20cookies%2015.11.2018.pdf'>
                    <FormattedMessage {...messages.cookiesLink} />
                  </a>
                ),
              }}
            />
          </p>
          {this.state.propertiesShow && (
            <Options>
              <Option
                name={<FormattedMessage {...messages.functional} />}
                alwaysActive
                detailsText={<FormattedMessage {...messages.functionalText} />}
              />
              <Option
                name={<FormattedMessage {...messages.statistic} />}
                detailsText={<FormattedMessage {...messages.statisticText} />}
                cookiesToggle={this.statisticCookiesToggle}
                checked={this.statisticChecked}
              />
              <Option
                name={<FormattedMessage {...messages.marketing} />}
                detailsText={<FormattedMessage {...messages.marketingText} />}
                cookiesToggle={this.marketingCookiesToggle}
                checked={this.marketingChecked}
              />
            </Options>
          )}
        </CookieConsent>
      </CookieConsentWrapper>
    );
  }
}

export default Cookies;
