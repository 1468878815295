import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { SizesProvider } from 'react-sizes';
import MobileDetect from 'mobile-detect';
import theme from 'theme';
import config from 'config';
import Cookies from 'components/Cookies';

const Container = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 16px;
  height: 100%;

  input,
  textarea,
  select,
  button {
    font-family: 'Montserrat', sans-serif;
  }
`;

const ProviderPage = (Page) =>
  class Provider extends Component {
    static getSizesFallback = (userAgent) => {
      const md = new MobileDetect(userAgent);

      if (md.mobile()) {
        return {
          fallbackWidth: 360,
          fallbackHeight: 640,
        };
      }

      if (md.tablet()) {
        return {
          fallbackWidth: 768,
          fallbackHeight: 1024,
        };
      }

      return {
        fallbackWidth: 1280,
        fallbackHeight: 700,
      };
    };

    static async getInitialProps(context) {
      let props;
      if (typeof Page.getInitialProps === 'function') {
        props = await Page.getInitialProps(context);
      }

      const { req } = context;
      const { locale, messages, headers } = req || window.__NEXT_DATA__.props;  

      const now = Date.now();

      return {
        ...props,
        locale,
        messages,
        now,
        headers,
      };
    }

    render() {
      const { messages, locale, now, headers, ...restProps } = this.props;

      const userAgent = headers?.['user-agent'] || '';

      const sizesConfig = Provider.getSizesFallback(userAgent);

      return (
        <ThemeProvider theme={theme}>
          <SizesProvider config={sizesConfig}>
            <IntlProvider
              messages={messages}
              locale={locale || 'pl'}
              defaultLocale={config.defaultLanguage}
              now={now}
              textComponent='span'
            >
              <Container>
                <Cookies />
                <Page {...restProps} />
              </Container>
            </IntlProvider>
          </SizesProvider>
        </ThemeProvider>
      );
    }
  };

ProviderPage.propTypes = {
  headers: PropTypes.object,
  locale: PropTypes.string,
  messages: PropTypes.object,
  now: PropTypes.number,
};

ProviderPage.defaultProps = {
  headers: null,
  locale: config.defaultLanguage,
  messages: {},
  now: null,
};

export default ProviderPage;
