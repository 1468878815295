const ArrowIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 24 24'
    {...props}
  >
    <path d='M8.122 24l-4.122-4 8-8-8-8 4.122-4 11.878 12z' />
  </svg>
);
export default ArrowIcon;
